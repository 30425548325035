.card-hover-bg{
    padding: 4px;
    border-radius: 4px;
}
.card-hover-bg:hover{
    background: rgba(0, 0, 0, 0.2);
}


.card-ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Adjust the width as needed */
}




/* pdf viewer */

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe {
  width: 80%;
  height: 0;
  border: none;
  padding-bottom: 56.25%; /* This is a 16:9 aspect ratio, adjust as needed */
  position: relative;
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* This is a 16:9 aspect ratio, adjust as needed */
  overflow: hidden;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
