.role-card:hover{
    cursor: pointer;
}
.role-card img{
    width: 95%;
    transition: width 0.5s ease;
}

.role-selected-true img{
   width: 100%;
}
.role-selected-true>div{
    color: #9c36b5;
    font-weight: 800;
 }

 #role-selection-btn{
    padding-left: 1rem;
    padding-right: 1rem;
 }

 @media (min-width:768px) {
    #role-selection-btn{
        padding-left: 2rem;
        padding-right: 2rem;
     }
 }