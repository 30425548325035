@tailwind base;
@tailwind components;
@tailwind utilities;

/*****************************************************/
/* Here are some layered classes */
/*****************************************************/
@layer components {
    /* buttons */
    .btn-style-1{
      @apply gradient-btn input input-bordered rounded-2xl text-center font-semibold
    } 

    .response-page-padding-x{
      @apply px-4 sm:px-6 md:px-8 lg:px-10
    }

    .response-page-padding-y{
      @apply py-8 md:py-12 lg:pt-16
    }


    .response-page-padding-x-lg{
      @apply px-8 lg:px-32
    }

    .response-font-size-title1{
      @apply text-h5 sm:text-h4 md:text-h3
    }

    .response-font-size-title2{
      @apply text-hint1 sm:text-h5 md:text-h4
    }

    .response-font-size-title3{
      @apply text-hint2 sm:text-hint1 md:text-h5 lg:text-h4
    }

    .response-font-size-content{
      @apply text-hint2 sm:text-hint1 md:text-h5
    }

    .response-font-size-note{
      @apply text-hint3 sm:text-hint2 md:text-hint1 
    }
  
    
  }
  
  
  


/*****************************************************/
/* Here are some customed utility-first css classes */
/*****************************************************/


/* Make img at the center of div regardless of the different shape : wrap a <img class="box-img"> inside <div class="img-box"> */
.img-box{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.box-img{
    min-width: 100%;
    min-height: 100%;
    display: block; 
    object-fit: cover;
}

/* Make an img takes full height and at placed at the center. Hidden things on left/right margins that does not fit the container */
.center-full-height-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}

.center-full-height-box-img {
  height: 100%;
  min-width: 100%;
  object-fit: cover; 
}



/* textarea */
.gray-text-area{
  background-color: #adb5bd;  /* bg-gray-4*/
  border-radius: 12px;
  padding: 1rem 2rem;
  resize: none;
  outline: none;
}
.gray-text-area::placeholder {
  color: #495057;  /* bg-gray-6*/
}

.gradient-btn{
  background-image: radial-gradient(ellipse at center, #fbe5ff, #f3c4fb);
}