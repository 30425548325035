/***********************************************************/
/*                         footer                          */
/***********************************************************/

/* .landing-footer{
    background: linear-gradient(30deg,#c2dac1, #e0ead2);
} */

.landing-footer{
    background-color: #242d45;
    color:#fff;
}

.footer-intput-label{
    line-height: 1rem;
    height: 2.5rem;
}

.footer-submit-btn{
    min-height: 2.5rem;
    height: 2.5rem;
}