/***********************************************************/
/*                           header                        */
/***********************************************************/
/* .landing-header{
    border: 8px solid #dbe8d0;
    background: linear-gradient(30deg, #e0ead2, #c2dac0);
} */

.landing-header{
    /* border: 8px solid #dbe8d0; */
    color:#fff;
    background: linear-gradient(30deg, #4e294f, #242d45);
}

.sign-up-btn{
    border-radius: 1rem;
    color:#000;
    background-image: radial-gradient(ellipse at center, #fff, #ced4da);
    padding: 0.25rem 1rem;
    transition: all 0.5s ease;
}
.sign-up-btn:hover{
    background-image: radial-gradient(ellipse at center, #fff, #adb5bd);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}


.landing-menu-items:hover{
    background-color: rgba(0, 0, 0, .1);
}


@media (max-width: 1024px){

    .sign-up-btn{
        width: 100%;
    }

}