
/* section 1 */
.color-product-teachers{
    color:#f7a5ba;
}

.color-product-parents{
    background: -webkit-linear-gradient(left, #c67aa6, #7d7bcd);  
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.color-product-children{
    color: #687ad8;
}

.product-section-1-line{
    /* aspect-ratio: 4/1; */
    width: 150%;
    height: 100%;

    position: absolute;
    left:0;
    top: 0;
    transform: translate(-20%, 10%);
    z-index: 0;
}



/* section 2 */
.product-gradient-1{
    border-radius: 8px;
    background-image: linear-gradient(30deg,#d1d7dd, #faf3d5);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

/* section 3 */
