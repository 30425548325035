@import url(./assets/css/index.css);                  
@import url(./assets/css/animations.css);              /* animations */
@import url(./assets/css/buttons.css);                 /* buttons */
@import url(./assets/css/chatting-bubbles.css);        /* speech bubbles */
@import url(./assets/css/shadows.css);                 /* shadows */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



/* fonts */
@font-face {
  font-family: 'Kodchasan';
  src: url('./assets/fonts/Kodchasan/Kodchasan-Regular.ttf') format('ttf'),
       url('./assets/fonts/Kodchasan/Kodchasan-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'MavenPro';
  src: url('./assets/fonts/Maven_Pro/MavenPro-VariableFont_wght.ttf') format('woff2'),
}

@font-face {
  font-family: "Monofett";
  src: url("./assets/fonts/Monofett/Monofett-Regular.ttf") format('ttf');
}