

.bg-support-hero{
    background-image: url("../../assets/imgs/bg-support.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;  
}

.bg-floating-white{
    background-image: url("../../assets/imgs/landing-float-white.png");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;  
}

.support-card{
    border-radius: 12px;
    background-image: linear-gradient(135deg, #c9dbf8, #e8f8c9);
}

