.sibliing-pagination-btn{
    width: 34px;
    height: 34px;
    border-radius: 100px;
    padding: 5px;
    transition: background-color 0.5s ease;
}

.sibliing-pagination-btn:hover{
    background-color: rgba(0, 0, 0, 0.3);
}

.pagination-btn-disable:hover{
    background-color: inherit;
}

.pagination-btn-activate{
    background-color:#fceec7;
}
.pagination-btn-activate:hover{
    background-color:#ffd35c;
}