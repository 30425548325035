/* box shadow */
.shadow-paper{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}


.shadow-card{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}


.shadow-thik{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
         rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
         rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.shadow-bubblestyle{
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}
  
.shadow-shift-lb{
    -webkit-box-shadow: -15px 15px 2px 1px rgba(0,0,0,0.33);
    -moz-box-shadow: -15px 15px 2px 1px rgba(0,0,0,0.33);
    box-shadow: -15px 15px 2px 1px rgba(0,0,0,0.33);
}  
