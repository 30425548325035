
/***********************************************************/
/*                        shaking                          */
/***********************************************************/
.shaking-3{
    transition: transform 0.5s ease-in-out;
  }
  .shaking:hover{
    animation: shakingAnimation-3 0.5s ease-in-out;;
  }
  @keyframes shakingAnimation-3 {
    0%, 100% { transform: rotate(0); }
    25% { transform: rotate(1deg); }
    75% { transform: rotate(-2deg); }
  }
  
  

/***********************************************************/
/*                     scaling on hover                    */
/***********************************************************/
  .scaling-5{
    transition: transform 0.5s ease;
  }
  .scaling-5:hover{
    transform: scale(105%);
  }
  
  
  

/***********************************************************/
/*                       fade in/out                       */
/***********************************************************/
  .fade-out {
    animation: fadeOut 1s forwards; 
  }
  .fade-in {
    animation: fadeIn 1s forwards; 
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  