.signup{ 
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    background-image: linear-gradient(30deg, #ff9a8b, #fff, #fff, #d3b3ff);
}


.signup-border-top{
    border-top: 1px dotted #ced4da;
}

.signup-height{
    min-height: 2rem;
    height: 2rem;
    border-radius: 12px;
}

@media (min-width:768px) {
    .signup-height{
        min-height: 3rem;
        height: 3rem;
        border-radius: 16px;
    }
}



.role-card:hover{
    cursor: pointer;
}
.role-card img{
    width: 95%;
    transition: width 0.5s ease;
}

.role-selected-true img{
   width: 100%;
}
.role-selected-true>div{
    color: #9c36b5;
    font-weight: 800;
 }

 #role-selection-btn{
    padding-left: 1rem;
    padding-right: 1rem;
 }

 @media (min-width:768px) {
    #role-selection-btn{
        padding-left: 2rem;
        padding-right: 2rem;
     }
 }