.bg-aboutus-1{
    background-image: url("../../assets/imgs/bg-about-us-1.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;  
}
.about-us-title-boarder{
    border-left: 5px solid #aa205e;
    border-radius: 4px;
    padding-left: 0.5rem;
}


.bg-aboutus-2{
    background-image: url("../../assets/imgs/bg-about-us-2.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;  
}

.about-us-btn-1{
    background-color: #f3f3f3;
}
.about-us-btn-1:hover{
    background-color: #e9ecef;
}
.about-us-bar{
    border-top: 3px #f3f3f3 solid;
}


.about-us-btn-2{
    background-color: #242c44;
    color: #fff;
}
.about-us-btn-2:hover{
    background-color: #352d4a;
}

.link-color{
    color:#7d4da7 
}