.container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 9/16;
    overflow: auto;
  }
  
  .iframe {
    width: 100%;
    height: 100%;
    height: auto;
    aspect-ratio: auto 1 / 1;
    overflow: hidden;
  }

  /* .container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 56.25%; 
  }
  
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
   */
  
/* 
   .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .aspect-ratio-box {
    width: 80%;
    padding-top: 45%; 
    position: relative;
  }
  
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
   */