
/***********************************************************/
/*                        bg colors                        */
/***********************************************************/
/* plain */
.bg-landing-0{
    background-color: #f5f0fe;
}

.bg-landing-1{
    background-color: #faf8ef;
}

/* gradient */
.bg-landing-2{
    background-image: linear-gradient(#fceec5, #f9f7f0);
}
.bg-landing-3{
    background-image: linear-gradient(#e0d8ff, #fdfdfd);
}
.bg-landing-4{
    background-image: linear-gradient(30deg,#78afe2, #b8d8f5, #78afe2);
}

.bg-landing-5{
    background-image: linear-gradient(30deg,#fbf092, #f5eed6, #fbf092);
}
.bg-landing-6{
    background-image: linear-gradient( #b3d5f5, #d6e9fa);
}



/***********************************************************/
/*                        section 1                        */
/***********************************************************/
.bg-section-1{
    background-image: linear-gradient(#c7e4e7, #c5dee6 , #c6e5e6);
}




/***********************************************************/
/*                        section 2                        */
/***********************************************************/
.rounded-inf{
    border-radius: 100000px;
}
.border-landing-section2{
    border: 1px black solid;
}
.bg-landing-section-2{
    background-image: url("../../assets/imgs/bg-landing-section2.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; 
}
.landing-section2-btn{
    background-color: #333;
    color: #fff;
}
.landing-section2-btn:hover{
    background-color: #252525;
}
.landing-section2-card{
    border: 2px solid #fff;
    padding: 0.5rem;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media (min-width: 768px){
    .landing-section2-card{
        padding: 1rem;
    }
}

/* .non-cog-block{
    width: 35vw;
    aspect-ratio: 28/45;
    position: absolute;
}
.non-cog-block button{
    transform: translate(-50%, -50%);
}
.non-cog-block-1{
    position: relative;
    top: 32%;
    left: 15%;
}
.non-cog-block-2{
    position: relative;
    top: 50%;
    left: 18%;
}
.non-cog-block-3{
    position: relative;
    top: 68%;
    left: 35%;
}

.component-skills-color{
    background: -webkit-linear-gradient(left, #004898, #008000, #f94144);  
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
} */


/* shadow bubble */
.shadow-bubble{
    position: absolute;
    aspect-ratio: 1;

    height: 90%;
    transition: left 1s ease;
    left: calc(100vw + 80%) ;
    transform: translate(-50%, 0);

    background-image: url("../../assets/icons/shadow-circle-light.svg");
    background-position: center center;
    background-size: cover; 
    background-repeat: no-repeat;  
    z-index: 2;

    padding: 6rem;
    display: flex;
    align-items: center;

}
.shadow-bubble:hover{
    cursor: pointer;
}

.shadow-bubble-center-true{
    left: 50vw;
}

.inline-link{
    color: #7d4da7;
}
.inline-link svg{
    display: inline-block;
    transform: translate(0.1rem, 0rem);
    /* display: flex;
    justify-content: center;
    align-items: center; */
}


/* .landing-carousel-container {
    position: relative;
    overflow: hidden;
}
  
.landing-carousel {
    display: flex;
    transition: transform 0.5s ease;  

}
  
.landing-carousel-item {
    flex: 0 0 100%;  
}
   */

/***********************************************************/
/*                        section 3                        */
/***********************************************************/
.bg-section-3{
    background-image: linear-gradient(#c8dce8, #def9fb , #c2dae6);
}

.landing-section-3-content img{
    transform: translate(-15%, 0);
}
.landing-section-3-content>div{
    transform: translate(15%, 33%);
    /* position: relative; */
}


.bg-floating-board{
    /* aspect-ratio: 4.24; */
    background-image: url("../../assets/imgs/landing-floating.png");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;  
}


.section-3-shadow{
    width: 100%;
    aspect-ratio: 1;
    /* background-image: url("../../assets/icons/shadow-circle.svg"); */
    background-image: url("../../assets/icons/shadow-circle-light.svg");
    background-position: center center;
    background-size: cover; 
    background-repeat: repeat;  

    /* transition: transform 0.5s ease; */
}
.section-3-shadow:hover{
    cursor: pointer;
    /* transform: scale(105%); */
}

.landing-section-3-content>div>button{
    /* position: absolute; */
    bottom: 0;
    right: -15%;
    z-index: 10;
}
.landing-section-3-content>div>button:hover{
    cursor: pointer;
}



/* section 4 */
.bg-landing-section-4{
    background-image: url("../../assets/imgs/landing-bg-4.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; 
}

.section-4-img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150%;
    overflow: hidden;
}

.h-splitter-orange{
    background-color: #fdc321;
    content: "";
    width: 40%;
    height: 1rem;

}

/***********************************************************/
/*                         media query                          */
/***********************************************************/

/* tablets */
@media (max-width: 1024px){
    .shadow-bubble{
        height: 100%;
        padding: 4rem;
   }   
}
